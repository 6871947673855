import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import pageOptions from '@/config/pageoptions'

// plugins
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import VueBootstrap from 'bootstrap-vue'
import VueMoment from 'vue-moment'
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vueditor from '@agametov/vueditor'
import VueHljs from 'vue-hljs'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueSelect from 'vue-select'
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueFullCalendar from 'vue-full-calendar'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

// plugins css
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/vue-hljs.min.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'fullcalendar/dist/fullcalendar.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'

// app css
import './assets/css/default/app.min.css'
import './assets/css/default/app.custom.css'
import './assets/css/default/theme/orange.min.css'
import './scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'


Vue.config.productionTip = false
Vue.prototype.pageOptions = pageOptions

import App from './App.vue'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.use(VueBootstrap)
Vue.use(VueMoment)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, { locale: 'en' })
Vue.use(VueSparkline)
Vue.use(Vueditor)
Vue.use(VueHljs)
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueGoodTable)
Vue.use(VueFullCalendar)
Vue.use(VueColorpicker)
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})

Vue.prototype.$http = axios;

localize("en", en);

Vue.component('v-select', VueSelect);
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component(VueCountdown.name, VueCountdown);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

store.commit('initialiseStore')

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
