<template>
    <b-modal id="estimateModal" title="New Estimate" button-size="sm" hide-footer>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form name="estimate" @submit.stop.prevent="handleSubmit(onSubmit)" ref="estimateForm">

                <validation-provider name="name" rules="required|min:5" v-slot="validationContext">
                    <b-form-group label="Name" label-for="fullname">
                        <b-form-input
                            id="fullname"
                            name="fullname"
                            v-model="form.fullname"
                            :state="getValidationState(validationContext)"
                            aria-describedby="fullname-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="fullname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="email" rules="required|min:5|email" v-slot="validationContext">
                    <b-form-group label="Email" label-for="email">
                        <b-form-input
                            id="email"
                            name="email"
                            v-model="form.email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="email-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="email-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="phonenumber" rules="required|min:5" v-slot="validationContext">
                    <b-form-group label="Phone Number" label-for="phonenumber">
                        <b-form-input
                            id="phonenumber"
                            name="phonenumber"
                            v-model="form.phonenumber"
                            :state="getValidationState(validationContext)"
                            aria-describedby="phonenumber-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="phonenumber-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="address" rules="required|min:5" v-slot="validationContext">
                    <b-form-group label="Address" label-for="address">
                        <b-form-input
                            id="address"
                            name="address"
                            v-model="form.address"
                            :state="getValidationState(validationContext)"
                            aria-describedby="address-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="address-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="town / city" rules="required|min:4" v-slot="validationContext">
                    <b-form-group label="Town / City" label-for="city">
                        <b-form-input
                            id="city"
                            name="city"
                            v-model="form.city"
                            :state="getValidationState(validationContext)"
                            aria-describedby="city-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="city-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="jobid" rules="required|min:1|numeric" v-slot="validationContext">
                    <b-form-group label="Job ID" label-for="jobid">
                        <b-form-input
                            id="jobid"
                            type="number"
                            name="jobid"
                            v-model="form.jobid"
                            :state="getValidationState(validationContext)"
                            aria-describedby="jobid-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="jobid-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="quoteid" rules="required|min:1|numeric" v-slot="validationContext">
                    <b-form-group label="Quote ID" label-for="quoteid">
                        <b-form-input
                            id="quoteid"
                            type="number"
                            name="quoteid"
                            v-model="form.quoteid"
                            :state="getValidationState(validationContext)"
                            aria-describedby="quoteid-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="quoteid-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="products" rules="required|min:1" v-slot="validationContext">
                    <b-form-group label="Products" label-for="products">
                        <b-form-checkbox-group
                            id="products"
                            name="products"
                            v-model="form.products"
                            :state="getValidationState(validationContext)"
                            aria-describedby="products-live-feedback"
                        >
                            <b-form-checkbox value="Slab">Slab</b-form-checkbox>
                            <b-form-checkbox value="Excavation">Excavation</b-form-checkbox>
                            <b-form-checkbox value="Drainage">Drainage</b-form-checkbox>
                            <b-form-checkbox value="Insulfound">Insulfound</b-form-checkbox>
                        </b-form-checkbox-group>
                        <b-form-invalid-feedback id="products-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <template v-if="form.products.includes('Slab')">
                    <validation-provider name="slabtype" rules="required" v-slot="validationContext">
                        <b-form-group label="Slab Type" label-for="slabtype">
                            <b-form-select
                                id="slabtype"
                                name="slabtype"
                                :options="slabtypes"
                                v-model="form.slabtype"
                                :state="getValidationState(validationContext)"
                                aria-describedby="slabtype-live-feedback"
                            ></b-form-select>
                            <b-form-invalid-feedback id="slabtype-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </template>

                <b-button variant="default" @click="hideModal()">Cancel</b-button>
                &nbsp;
                <b-button variant="themex" type="submit" :disabled="!create">Create</b-button>

            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>

export default {
	name: 'NewEstimate',
	data() {
        return {
            form: {
                fullname: '',
                email: '',
                phonenumber: '',
                address: '',
                city: '',
                jobid: 0,
                quoteid: 0,
                products: [],
                slabtype: 0
            },
            slabtypes: {},
            create: true
        }
    },

    created() {
        this.$http.get('/slabsnz/slabtypes').then((response) => {
            this.slabtypes = response.data
        })
    },

    methods: {
        showModal() {
            this.$bvModal.show('estimateModal')
        },
        hideModal() {
            this.resetForm()
            this.$bvModal.hide('estimateModal')
        },
        createEstimate(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.$refs.estimateForm.submit()
        },

        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },

        resetForm() {
            this.form = {
                fullname: '',
                email: '',
                phonenumber: '',
                address: '',
                city: '',
                jobid: 0,
                quoteid: 0,
                products: [],
                slabtype: 0
            }

            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },

        onSubmit() {
            this.create = false

            this.$http.post('/slabsnz/estimates', this.form).then(response => {
                console.log(response)
                this.create = true
                this.hideModal()
                this.$emit('reloadEstimates')
            }, response => {
                console.log(response)
                this.create = true
            })
        }
    }
}
</script>
