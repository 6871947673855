var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.pageOptions.pageEmpty)?_c('div',{staticClass:"fade page-sidebar-fixed page-header-fixed show page-container",class:{ 
	'page-sidebar-minified': _vm.pageOptions.pageSidebarMinified, 
	'page-content-full-height': _vm.pageOptions.pageContentFullHeight, 
	'page-without-sidebar': _vm.pageOptions.pageWithoutSidebar, 
	'page-with-two-sidebar': _vm.pageOptions.pageWithTwoSidebar,
	'page-with-wide-sidebar': _vm.pageOptions.pageWithWideSidebar,
	'page-with-light-sidebar': _vm.pageOptions.pageWithLightSidebar,
	'page-with-top-menu': _vm.pageOptions.pageWithTopMenu,
	'page-sidebar-toggled': _vm.pageOptions.pageMobileSidebarToggled,
	'page-right-sidebar-toggled': _vm.pageOptions.pageMobileRightSidebarToggled || _vm.pageOptions.pageRightSidebarToggled,
	'page-right-sidebar-collapsed': _vm.pageOptions.pageRightSidebarCollapsed,
	'has-scroll': _vm.pageOptions.pageBodyScrollTop
}},[_c('Header'),(_vm.pageOptions.pageWithTopMenu)?_c('TopMenu'):_vm._e(),(!_vm.pageOptions.pageWithoutSidebar)?_c('Sidebar'):_vm._e(),_c('div',{staticClass:"content",class:{ 'content-full-width': _vm.pageOptions.pageContentFullWidth, 'content-inverse-mode': _vm.pageOptions.pageContentInverseMode },attrs:{"id":"content"}},[_c('div',{staticClass:"tag-nav-wrapper"},[_c('tag-navigation',{attrs:{"value":_vm.$route,"list":_vm.tagNavList},on:{"input":_vm.handleClick,"on-close":_vm.handleCloseTag}})],1),_c('keep-alive',{attrs:{"include":_vm.cacheList}},[_c('router-view')],1),_c('vue-ins-progress-bar')],1),(_vm.pageOptions.pageWithFooter)?_c('Footer'):_vm._e()],1):_c('div',[_c('router-view'),_c('vue-ins-progress-bar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }