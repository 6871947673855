export default {
  state: {
    id: 0,
    name: '',
    title: '',
    email: '',
    avatorImgPath: '',
    token: localStorage.getItem('token') || '',
    roles: 0,
    status: ''
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, data) {
      state.status = 'success'
      state.token = data.token
      state.id = data.user.id
      state.name = data.user.name
      state.title = data.user.title
      state.email = data.user.email
      state.roles = data.user.roles
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
    setAvator (state, avatorPath) {
      state.avatorImgPath = avatorPath
    },
    setUserId (state, id) {
      state.userId = id
    },
    setUserName (state, name) {
      state.userName = name
    },
    setAccess (state, access) {
      state.access = access
    },
    setToken (state, token) {
      state.token = token
    },
    initialiseStore(state) {
      if(!state.token.length)
        return

      let userInfo = JSON.parse(atob(state.token.split('.')[1]));
      state.id = userInfo.id
      state.name = userInfo.name
      state.title = userInfo.title
      state.email = userInfo.email
      state.roles = userInfo.roles
    }
  },
  actions: {
      login({commit}, user) {
        return new Promise((resolve, reject) => {
          commit('auth_request')
          this._vm.$http.post('/user/login', user)
          .then(response => {
            const token = response.data.token
            const user = response.data.user
            localStorage.setItem('token', token)
            this._vm.$http.defaults.headers.common['Authorization'] = token
            commit('auth_success', { 'token': token, 'user': user })
            resolve(response)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err.response.data.message)
          })
        })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete this._vm.$http.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    roles: state => state.roles,
    authStatus: state => state.status,
  },
}
