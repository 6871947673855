<script>
const sidebarMenu = [
  { path: '/dashboard', icon: 'fa fa-th', title: 'Dashboard' },
  { path: '/contacts', icon: 'fa fa-address-card', title: 'Contacts' },
  { path: '/projects', icon: 'fa fa-list-ol', title: 'Projects' },
  { path: '/stock', icon: 'fa fa-list-ol', title: 'Stock' },
  { path: '/insulfound', icon: 'fa fa-database', title: 'Insulfound',
      children: [
          { path: '/insulfound/quotes', title: 'Quotes' },
          { path: '/insulfound/orders', title: 'Orders' },
      ] 
  },
  { path: '/slabsnz', icon: 'fa fa-database', title: 'Slabs NZ',
      children: [
          { path: '/slabsnz/estimates', title: 'Estimates' }
      ] 
  },
  { path: '/workshop', icon: 'fa fa-cube', title: 'Workshop',
      children: [
          { path: '/workshop/clock', title: 'Time clock' },
          { path: '/workshop/report', title: 'Time report' }
      ] 
  },
  { path: '/admin', icon: 'fa fa-cogs', title: 'Administration',
      children: [
          { path: '/admin/accounts', title: 'Accounts' }
      ]
  }
]

export default sidebarMenu;
</script>
