<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><a href="javascript:;">InsulFound</a></li>
			<li class="breadcrumb-item"><router-link :to="{ name: 'InsulFoundQuotes' }">Quotes</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Quote #{{quote.id}}</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header hidden-print">InsulFound Quote <small>{{quote.id}}</small></h1>
        <!-- end page-header -->
        <!-- begin invoice -->
        <div class="invoice">
            <!-- begin invoice-company -->
            <div class="invoice-company">
                &nbsp;
                <span class="pull-right hidden-print">
                    <a href="javascript:;" class="btn btn-sm btn-info m-b-10"><i class="fa fa-thumbs-up t-plus-1 fa-fw fa-lg"></i> Promote to Order</a>
                    <a href="javascript:;" class="btn btn-sm btn-white m-b-10" @click="handleDownloadQuotePdf()" :class="{ disabled: !exportAsPdf }"><i class="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i> Export as PDF</a>
                </span>
                Placeholder
            </div>
            <!-- end invoice-company -->
            <!-- begin invoice-header -->
            <div class="invoice-header">
                <div class="invoice-from">
                    <small>From</small>
                    <address class="m-t-5 m-b-5">
                        <strong class="text-inverse">InsulFound</strong><br />
                        81B Buchanans Rd, Hei Hei<br />
                        Christchurch, NZ, 8042<br />
                    </address>
                </div>
                <div class="invoice-to">
                    <small>To</small>
                    <address class="m-t-5 m-b-5">
                        <strong class="text-inverse" v-if="quote.company">{{quote.company}}</strong><br />
                        {{quote.name}}<br />
                        {{quote.address}}<br />
                        Phone: {{quote.phonenumber}}<br />
                        Email: {{quote.email}}
                    </address>
                </div>
                <div class="invoice-date">
                    <small><b>Information</b></small>
                    <div class="date text-inverse m-t-5">QUOTE</div>
                    <div class="invoice-detail">
                        #{{quote.id}}<br />
                        {{quote.createdAt}}<br />
                        GST #: 126-379-749
                    </div>
                </div>
            </div>
            <!-- end invoice-header -->
            <!-- begin invoice-content -->
            <div class="invoice-content col-md">
                <div v-if="quote.comments" style="width:100%;"> 
                    <h5>Comments</h5>
                    <vue-xeditable
                        style="width:100%;"
                        type="textarea"
                        name="comment"
                        :value.sync='quote.comments'
                        :enable-editing="true"
                        @start-editing="sendStartEditingEvent"
                        @stop-editing="sendStopEditingEvent"
                        @value-will-change="sendValueWillChangeEvent"
                        @value-did-change="sendValueDidChangeEvent"
                         />
                </div>
                <!-- begin table-responsive -->
                <div class="table-responsive">
                    <table class="table table-invoice table-striped">
                        <thead>
                            <tr>
                                <th>PROFILE</th>
                                <th>FINISH</th>
                                <th>CORE</th>
                                <th>R-VALUE</th>
                                <th>HEIGHT</th>
                                <th>METERS</th>
                                <th>RATE</th>
                                <th v-if="promotionOrDiscount()">DISCOUNT RATE</th>
                                <th>SUBTOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in quote.lineitems" :key="index">
                                <td>
                                    {{item.profile}} InsulFound
                                    <br />
                                    <small><b>{{item.thickness}}mm</b></small>
                                </td>
                                <td>{{item.finish}}</td>
                                <td>{{item.core}}</td>
                                <td>{{item.rValue}}</td>
                                <td>{{item.depth}}mm</td>
                                <td>{{item.lineal}}M</td>
                                <td>${{item.rate}}</td>
                                <td v-if="promotionOrDiscount()">${{lineItemDiscountRate(item)}}</td>
                                <td><b>${{lineItemSubTotal(item)}}</b></td>
                            </tr>
                            <tr v-if="quote.additionalitems !== undefined && quote.additionalitems !== null && quote.additionalitems.length > 0">
                                <th colspan="7"><b>ADDITIONAL ITEMS</b></th>
                            </tr>
                            <tr v-for="(item, index) in quote.additionalitems" :key="index">
                                <td>
                                    {{item.name}}
                                    <template v-if="item.count > 0">
                                        <br />
                                        <small><b>{{item.count}} sheets</b></small>
                                    </template>
                                </td>
                                <td></td>
                                <td>{{item.core}}</td>
                                <td></td>
                                <td></td>
                                <td>{{item.lineal}}<span v-if="item.lineal > 0">M</span></td>
                                <td>${{item.rate}}</td>
                                <td v-if="promotionOrDiscount()">${{lineItemDiscountRate(item)}}</td>
                                <td><b>${{lineItemSubTotal(item)}}</b></td>
                            </tr>
                            <tr v-if="promotionOrDiscount() || quote.discount">
                                <td colspan="9" v-if="quote.promo">
                                    Discount - Promo code {{quote.promo.code}}<br />
                                    <small><b>{{quote.promo.name}}</b></small>
                                </td>
                                <td colspan="9" v-if="quote.discount">
                                    Discount - Trade account<br />
                                    <small><b>{{quote.discount}}%</b></small>
                                </td>
                            </tr>
                            <tr v-if="quote.freight">
                                <td colspan="6">
                                    Freight
                                    <br />                
                                    <small><b>{{quote.freight.name}}</b></small>
                                </td>
                                <td v-if="promotionOrDiscount"></td>
                                <td></td>
                                <td><b>${{quote.freightcost}}</b></td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    Additional Freight
                                </td>
                                <td v-if="promotionOrDiscount"></td>
                                <td></td>
                                <td><vue-xeditable
                                    type="number"
                                    name="additionalfreight"
                                    :value.sync='quote.additionalfreightcost'
                                    :enable-editing="true"
                                    @start-editing="sendStartEditingEvent"
                                    @stop-editing="sendStopEditingEvent"
                                    @value-will-change="sendValueWillChangeEvent"
                                    @value-did-change="sendValueDidChangeEvent"
                                     />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- end table-responsive -->
                <!-- begin invoice-price -->
                <div class="invoice-price">
                    <div class="invoice-price-left">
                        <div class="invoice-price-row">
                            <div class="sub-price">
                                <small>SUBTOTAL</small>
                                <span class="text-inverse">${{quote.subtotal}}</span>
                            </div>
                            <div class="sub-price">
                                <i class="fa fa-plus text-muted"></i>
                            </div>
                            <div class="sub-price">
                                <small>GST (15%)</small>
                                <span class="text-inverse">${{quote.gst}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-price-right">
                        <small>TOTAL</small> <span class="f-w-600">${{quote.total}}</span>
                    </div>
                </div>
                <!-- end invoice-price -->
            </div>
            <!-- end invoice-content -->
            <!-- begin invoice-note -->
            <div class="invoice-note">
                <span>* This statement is indicative of a quote and not a final order.</span>
                <br />
                * If you have any questions concerning this quote please get in touch.
            </div>
            <!-- end invoice-note -->
            <!-- begin invoice-footer -->
            <div class="invoice-footer">
                <p class="text-center m-b-5 f-w-600">
                    THANK YOU FOR YOUR BUSINESS
                </p>
                <p class="text-center">
                    <span class="m-r-10"><i class="fa fa-fw fa-lg fa-globe"></i> insulfound.co.nz</span>
                    <span class="m-r-10"><i class="fa fa-fw fa-lg fa-phone-volume"></i> T: 0800 4678536</span>
                    <span class="m-r-10"><i class="fa fa-fw fa-lg fa-envelope"></i> sales@insulfound.co.nz</span>
                </p>
            </div>
            <!-- end invoice-footer -->
        </div>
        <!-- end invoice -->
    </div>
</template>
<style>
textarea.vue-xeditable-form-control {
    padding: 5px;
    box-sizing: content-box;
    color: #000;
    min-width:500px;
    background-color: #fff;
    background-image: none;
    outline: none;
  }
</style>
<script>
    import { XEditable } from '@onekiloparsec/vue-xeditable'
    export default {
        props: ['id'],
        components: {
            'vue-xeditable': XEditable
        },
        data () {
            return {
                quote: {
                    promo: {}
                },
                exportAsPdf: true
            }
        },

        watch: {
            '$route' () { 
               this.loadQuote(); 
            }
        },

        created () {
            this.loadQuote();
        },

        computed: {

        },

        methods: {
            loadQuote()
            {
                this.$http.get('/insulfound/quote/' + this.id).then(response => {
                    this.quote = response.data
                })
            },

            handleDownloadQuotePdf() {
                this.exportAsPdf = false
                this.$http.get('/insulfound/quote/' + this.id + '/pdf', { responseType: 'arraybuffer' }).then(response => {
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Quote #' + this.id + '.pdf'
                    link.click()
                    this.exportAsPdf = true
                })
            },

            promotionOrDiscount() {
                if(this.quote.promotionid && this.quote.promo.effect > 0)
                    return true

                return false
            },

            lineItemDiscountRate(item) {
                if(this.quote.promotionid && this.quote.promo.effect > 0)
                    return Number((item.rate * (this.quote.promo.effect / 100)).toFixed(2))
                else
                    return item.rate
            },

            lineItemSubTotal(item) {
                if(this.quote.promotionid && this.quote.promo.effect > 0)
                    return Number((item.subtotal - (item.subtotal * (this.quote.promo.effect / 100))).toFixed(2))
                else
                    return item.subtotal
            },


            sendStartEditingEvent (value, name) {
                console.log(`sendStartEditingEvent ${value} - ${name}`)
                //this.pushEditingEvent('start-editing', value, name)
            },
            sendStopEditingEvent (value, name) {
                console.log(`sendStopEditingEvent ${value} - ${name}`)
                //this.pushEditingEvent('stop-editing', value, name)
            },
            sendValueWillChangeEvent (value, name) {
                console.log(`sendValueWillChangeEvent ${value} - ${name}`)
                //this.pushEditingEvent('value-will-change', value, name)
            },
            sendValueDidChangeEvent (value, name) {
                console.log(`sendValueDidChangeEvent ${value} - ${name}`)
               // this.pushEditingEvent('value-did-change', value, name)
                switch(name) {
                    case 'additionalfreight':
                        this.quote.additionalfreightcost = value
                        this.$http.post('/insulfound/quote/' + this.id, this.quote).then(response => {
                            this.quote = response.data
                        })
                        break;
                }
            },
        }
    }
</script>