<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Stock</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">Stock</h1>
        <!-- end page-header --> 
        <!-- begin nav-tabs-pills -->
        <b-tabs pills nav-class="mb-3" content-class="p-15 rounded bg-white mb-4">
            <b-tab active>
                <template slot="title">
                    <span class="d-sm-none">Category 1</span>
                    <span class="d-sm-block d-none">Category 1</span>
                </template>
                
                <h3 class="m-t-10">Category 1</h3>
                <p>
                    
                </p>
            </b-tab>
            <b-tab>
                <template slot="title">
                    <span class="d-sm-none">Category 2</span>
                    <span class="d-sm-block d-none">Category 2</span>
                </template>
                
                <h3 class="m-t-10">Category 2</h3>
                <p>
                    
                </p>
            </b-tab>
            <b-tab>
                <template slot="title">
                    <span class="d-sm-none">Category 3</span>
                    <span class="d-sm-block d-none">Category 3</span>
                </template>
                
                <h3 class="m-t-10">Category 3</h3>
                <p>
                    
                </p>
            </b-tab>
        </b-tabs>
        <!-- end nav-tabs-pills -->
    </div>
</template>

<script>

    export default {
        data(){
            return{
                stockitems: []
            }
        },

        created: function()
        {
            
        },

        methods: {
            
        }
    }
</script>