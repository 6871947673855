<template>
	<div>
		<!-- begin breadcrumb -->
		<ol class="breadcrumb float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><a href="javascript:;">InsulFound</a></li>
			<li class="breadcrumb-item"><router-link :to="{ name: 'InsulFoundOrders' }">Orders</router-link></li>
		</ol>
		<!-- end breadcrumb -->
		<!-- begin page-header -->
		<h1 class="page-header">InsulFound Orders <small>List of orders</small></h1>
		<!-- end page-header --> 

        <vue-good-table 
            :columns="columns" 
            :rows="orders" 
            :lineNumbers="false"
            :search-options="{ enabled: true, placeholder: 'Search term' }" 
            :pagination-options="{ enabled: true,  position: 'bottom', perPage: 100 }"
            :sort-options="{ enabled: true, initialSortBy: { field: 'id', type: 'desc' } }"
            :fixed-header="true"
            @on-row-click="onRowClick"
            :selectOptions="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
            }"
        >
            <div slot="selected-row-actions" style="margin: -2px 0;">
                <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                <button class="btn btn-xs btn-primary">Action 2</button>
            </div>
            <template slot="table-row" slot-scope="props">
                {{props.formattedRow[props.column.field]}}
            </template>
        </vue-good-table>
	</div>
</template>

<script>

    export default {
        name: 'InsulFoundOrders',
        data() {
            return {
                orders: [],
                columns: [
                {
                    label: 'Order ID',
                    field: 'id',
                    width: '3%',
                    type: 'number',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Name',
                    field: 'name',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Company',
                    field: 'company',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Address',
                    field: 'address',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Email',
                    field: 'email',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap',
                    sortable: false,
                }, {
                    label: 'Payment Method',
                    field: 'paymentmethod',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap',
                    sortable: false,
                }, {
                    label: 'Status',
                    field: 'status',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap',
                    sortable: false,
                }, {
                    label: 'Total',
                    field: 'total',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap',
                    sortable: false,
                    formatFn: function(value) {
                        return '$' + value;
                    }
                }, {
                    label: 'Created On',
                    field: 'createdAt',
                    type: 'date',
                    dateInputFormat: 'dd/MM/yyyy h:mm:ss',
                    dateOutputFormat: 'MMMM dd, yyyy h:mm:ss',
                    width: '10%',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap'
                }]
            }
        },

        created ()
        {
            this.fetchItems();
        },

        methods: {
            fetchItems()
            {
                this.$http.get('/insulfound/orders').then((response) => {
                    this.orders = response.data
                })
            },
            onRowClick(params) {
                this.$router.push({ name: 'InsulFoundOrderOverview', params: { id: params.row.id } })
            }
        }
    }
</script>