<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><a href="javascript:;">InsulFound</a></li>
			<li class="breadcrumb-item"><router-link :to="{ name: 'InsulFoundOrders' }">Orders</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Order #{{order.id}}</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header hidden-print">InsulFound Order <small>{{order.id}}</small></h1>
        <!-- end page-header -->
        <!-- begin invoice -->
        <div class="invoice">
            <!-- begin invoice-company -->
            <div class="invoice-company">
                &nbsp;
                <span class="pull-right hidden-print">
                    <a href="javascript:;" class="btn btn-sm btn-white m-b-10"><i class="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i> Export as PDF</a>
                    <a href="javascript:;" onclick="window.print()" class="btn btn-sm btn-white m-b-10"><i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print</a>
                </span>
                Placeholder
            </div>
            <!-- end invoice-company -->
            <!-- begin invoice-header -->
            <div class="invoice-header">
                <div class="invoice-from">
                    <small>From</small>
                    <address class="m-t-5 m-b-5">
                        <strong class="text-inverse">InsulFound</strong><br />
                        81B Buchanans Rd, Hei Hei<br />
                        Christchurch, NZ, 8042<br />
                    </address>
                </div>
                <div class="invoice-to">
                    <small>To</small>
                    <address class="m-t-5 m-b-5">
                        <strong class="text-inverse" v-if="order.company">{{order.company}}</strong><br />
                        {{order.name}}<br />
                        {{order.address}}<br />
                        Phone: {{order.phonenumber}}<br />
                        Email: {{order.email}}
                    </address>
                </div>
                <div class="invoice-date">
                    <small><b>Information</b></small>
                    <div class="date text-inverse m-t-5">TAX INVOICE</div>
                    <div class="invoice-detail">
                        #{{order.id}}<br />
                        {{order.createdAt}}<br />
                        GST #: 126-379-749
                    </div>
                </div>
            </div>
            <!-- end invoice-header -->
            <!-- begin invoice-content -->
            <div class="invoice-content">
                <div v-if="order.comments"> 
                    <h5>Comments</h5>
                    <p>{{order.comments}}</p>
                </div>
                <!-- begin table-responsive -->
                <div class="table-responsive">
                    <table class="table table-invoice table-striped">
                        <thead>
                            <tr>
                                <th>PROFILE</th>
                                <th>FINISH</th>
                                <th>CORE</th>
                                <th>R-VALUE</th>
                                <th>HEIGHT</th>
                                <th>METERS</th>
                                <th>RATE</th>
                                <th v-if="order.promotionid && order.promo.effect > 0">DISCOUNT</th>
                                <th>SUBTOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in order.lineitems" :key="index">
                                <td>
                                    {{item.profile}} InsulFound
                                    <br />
                                    <small><b>{{item.thickness}}mm</b></small>
                                </td>
                                <td>{{item.finish}}</td>
                                <td>{{item.core}}</td>
                                <td>{{item.rValue}}</td>
                                <td>{{item.depth}}</td>
                                <td>{{item.lineal}}M</td>
                                <td>${{item.rate}}</td>
                                <td v-if="order.promotionid">${{item.rate}}</td>
                                <td><b>${{item.subtotal}}</b></td>
                            </tr>
                            <tr v-if="order.promotionid">
                                <td colspan="8">
                                    Discount<br />
                                    <small><b>{{order.promo.name}}</b></small>
                                </td>
                            </tr>
                            <tr v-if="order.freight">
                                <td colspan="6">
                                    Freight
                                    <br />                
                                    <small><b>{{order.freight.name}}</b></small>
                                </td>
                                <td v-if="order.promotionid"></td>
                                <td></td>
                                <td><b>${{order.freightcost}}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- end table-responsive -->
                <!-- begin invoice-price -->
                <div class="invoice-price">
                    <div class="invoice-price-left">
                        <div class="invoice-price-row">
                            <div class="sub-price">
                                <small>SUBTOTAL</small>
                                <span class="text-inverse">${{order.subtotal}}</span>
                            </div>
                            <div class="sub-price">
                                <i class="fa fa-plus text-muted"></i>
                            </div>
                            <div class="sub-price">
                                <small>GST (15%)</small>
                                <span class="text-inverse">${{order.gst}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-price-right">
                        <small>TOTAL</small> <span class="f-w-600">${{order.total}}</span>
                    </div>
                </div>
                <!-- end invoice-price -->
            </div>
            <!-- end invoice-content -->
            <!-- begin invoice-note -->
            <div class="invoice-note">
                <span v-if="order.user !== undefined && order.user.payHoliday">
                    <b>* Payment terms - 20th of following month</b>
                </span>
                <span v-else-if="order.user !== undefined && !order.user.payHoliday">
                    <b>* Payment terms - Cash Sale - Funds cleared prior to dispatch</b>
                </span>
                <span v-else>
                    <b>* Payment is due on dispatch of products unless paid already</b>
                </span>
                <br />
                * If you have any questions concerning this order please get in touch.
            </div>
            <!-- end invoice-note -->
            <!-- begin invoice-footer -->
            <div class="invoice-footer">
                <p class="text-center m-b-5 f-w-600">
                    THANK YOU FOR YOUR BUSINESS
                </p>
                <p class="text-center">
                    <span class="m-r-10"><i class="fa fa-fw fa-lg fa-globe"></i> insulfound.co.nz</span>
                    <span class="m-r-10"><i class="fa fa-fw fa-lg fa-phone-volume"></i> T: 0800 4678536</span>
                    <span class="m-r-10"><i class="fa fa-fw fa-lg fa-envelope"></i> sales@insulfound.co.nz</span>
                </p>
            </div>
            <!-- end invoice-footer -->
        </div>
        <!-- end invoice -->
    </div>
</template>

<script>
    export default {
        props: ['id'],
        data () {
            return {
                order: {}
            }
        },

        watch: {
            '$route' () { 
               this.loadOrder(); 
            }
        },

        created () {
            this.loadOrder();
        },

        methods: {
            loadOrder()
            {
                this.$http.get('/insulfound/order/' + this.id).then((response) => {
                    this.order = response.data[0]
                })
            },
        }
    }
</script>