<template>
    <b-modal id="jobSelectorModal" size="lg" title="Job Selector" hide-footer>
        <form-wizard ref="formwizard" @onComplete="onComplete">
            <tab-content title="Select Job" :selected="true">
                <fieldset>
                    <div class="row">
                        <div class="col-md-12">
                            <b-form name="clock" @submit.stop.prevent="onSubmit" ref="clockForm">
                                <div class="form-group">
                                    <label>Select Project <span class="text-danger">*</span></label>
                                    <select class="form-control" id="jobSelection" v-model="form.selectedJob">
                                        <option value="">Select Job</option>
                                        <option value="General Workshop - Insulfound">General Workshop - Insulfound</option>
                                        <option value="General Workshop - SIPs">General Workshop - SIPs</option>
                                        <option value="Offsite / Slabs">Offsite / Slabs</option>
                                        <option value="Administration">Administration</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Job ID (Optional)</label>
                                    <input type="text" class="form-control" v-model="form.jobId">
                                </div>
                                <div class="form-group">
                                    <label>Description <span class="text-danger">*</span></label>
                                    <textarea rows="5" size="5" class="form-control" v-model="form.jobDescription" required></textarea>
                                </div>
                                <button type="submit" class="btn btn-themex">Save</button>
                            </b-form>
                        </div>
                    </div>
                </fieldset>
            </tab-content>
            <tab-content title="Select Task">
                <fieldset>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group"><label>Select task for project <span id="wizardJob"></span></label><select class="form-control" id="taskSelection" autocomplete="off"><option value="">Select Job</option></select></div>
                        </div>
                    </div>
                </fieldset>
            </tab-content>
            <tab-content title="Select Task Job">
                <fieldset>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group"><label>Select job for task <span id="wizardTask"></span></label><select class="form-control" id="taskJobSelection" autocomplete="off"><option value="">Select Job</option></select></div>
                        </div>
                    </div>
                </fieldset>
            </tab-content>
        </form-wizard>
    </b-modal>
</template>
<script>
import FormWizard from "./form-wizard.vue"
import TabContent from "./tab-content.vue"

export default {
    name: 'jobSelector',
	data() {
        return {
            staticJobs: ['General Workshop - Insulfound', 'General Workshop - SIPs',  'Offsite / Slabs', 'Administration'],
            form: {
                jobId: '',
                selectedJob: '',
                jobDescription: ''
            }
        }
    },
    components: {
        FormWizard,
        TabContent
    },
    methods: {        
        showModal() {
            this.$bvModal.show('jobSelectorModal')
        },
        hideModal() {
            this.resetForm()
            this.$bvModal.hide('jobSelectorModal')
        },
        
        onComplete() {
            
        },

        resetForm() {
            this.form = {
                jobId: '',
                selectedJob: '',
                jobDescription: ''
            }
        },

        nextStep() {
            
        },

        previousStep() {
            
        },

        onSubmit: function() {
            if(!this.form.selectedJob)
                return

            if(this.staticJobs.includes(this.form.selectedJob)) {
                this.$emit('clockIn', this.form)
                this.hideModal()
                this.resetForm()
            } else {
                this.$refs.formwizard.jobSelectorValid = true
                this.$refs.formwizard.nextTab()
            }
        }
    }
}
</script>