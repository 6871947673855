<template>
  <div class="tags-nav">
    <b-dropdown variant="default" class="close-con">
      <template v-slot:button-content>
        <i class="fas fa-times-circle fa-lg"></i>
      </template>
      <b-dropdown-item href="#" @click="handleCloseOption('close-all')">Close All</b-dropdown-item>
      <b-dropdown-item href="#" @click="handleCloseOption('on-close')">Close Others</b-dropdown-item>
    </b-dropdown>

    <div class="btn-con left-btn">
      <a href="#" class="btn btn-default fas fa-arrow-circle-left fa-lg" @click="handleScroll(240)"></a>
    </div>
    <div class="btn-con right-btn">
      <a href="#" class="btn btn-default fas fa-arrow-circle-right fa-lg" @click="handleScroll(-240)"></a>
    </div>

    <div class="scroll-outer" ref="scrollOuter" @DOMMouseScroll="handlescroll" @mousewheel="handlescroll">
      <div ref="scrollBody" class="scroll-body" :style="{left: tagBodyLeft + 'px'}">
        <transition-group name="taglist-moving-animation">
          <div
            type="dot"
            v-for="(item, index) in list"
            ref="tagsPageOpened"
            :key="`tag-nav-${index}`"
            :name="item.name"
            :data-route-item="item"
            :closable="item.name === 'dashboard' ? false : true"
            class="btn-group m-l-10"
          >
            <a href="#" class="btn" @click="handleClick(item)" :class="isCurrentTag(item) ? 'btn-themex' : 'btn-default'">{{ showTitleInside(item) }}</a>
            <a href="#" class="btn btn-default fas fa-times-circle" v-show="item.name === 'Dashboard' ? false : true" @click="handleClose(item)"></a>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script>
import { showTitle, routeEqual } from '@/libs/util'
import './tag-navigation.less';
export default {
  name: 'tagNavigation',
  props: {
    value: Object,
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      tagBodyLeft: 0,
      rightOffset: 40,
      outerPadding: 4,
    }
  },
  computed: {
    currentRouteObj () {
      const { name, params, query } = this.value
      return { name, params, query }
    }
  },
  watch: {
    //'$route' (to) {
      //this.getTagElementByRoute(to)
    //},
  },
  methods: {
    handlescroll (e) {
      var type = e.type
      let delta = 0
      if (type === 'DOMMouseScroll' || type === 'mousewheel') {
        delta = (e.wheelDelta) ? e.wheelDelta : -(e.detail || 0) * 40
      }
      this.handleScroll(delta)
    },
    handleScroll (offset) {
      const outerWidth = this.$refs.scrollOuter.offsetWidth
      const bodyWidth = this.$refs.scrollBody.offsetWidth
      if (offset > 0) {
        this.tagBodyLeft = Math.min(0, this.tagBodyLeft + offset)
      } else {
        if (outerWidth < bodyWidth) {
          if (this.tagBodyLeft < -(bodyWidth - outerWidth)) {
            //this.tagBodyLeft = this.tagBodyLeft
          } else {
            this.tagBodyLeft = Math.max(this.tagBodyLeft + offset, outerWidth - bodyWidth)
          }
        } else {
          this.tagBodyLeft = 0
        }
      }
    },
    handleCloseOption(type) {
      if (type === 'close-all') {
        // Close all
        let res = this.list.filter(item => item.path === "/dashboard");
        this.$emit("on-close", res, "all");
        this.$router.push("/");
      } else {
        // Close others
        let res = this.list.filter(
          item => item.path === this.value.path || item.path === '/dashboard'
        );
        this.$emit('on-close', res, 'others');
      }
    },
    handleClose (route) {
      let res = this.list.filter(item => !routeEqual(route, item))
      this.$emit('on-close', res, undefined, route)
    },
    handleClick (item) {
      if(!this.isCurrentTag(item))
        this.$emit('input', item)
    },
    showTitleInside (item) {
      return showTitle(item)
    },
    getTagElementByRoute (route) {
      this.$nextTick(() => {
        this.refsTag = this.$refs.tagsPageOpened
        this.refsTag.forEach((item, index) => {
          if (routeEqual(route, item.$attrs['data-route-item'])) {
            let tag = this.refsTag[index].$el
            this.moveToView(tag)
          }
        })
      })
    },
    isCurrentTag (item) {
      return routeEqual(this.currentRouteObj, item)
    },
  },
  mounted () {
    setTimeout(() => {
      //this.getTagElementByRoute(this.$route)
    }, 200)
  }
}
</script>