<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
			<li class="breadcrumb-item"><router-link :to="{ name: 'SNZ' }">Slabs NZ</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Estimates</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header hidden-print">Estimates</h1>

        <NewEstimate ref="newEstimate" @reloadEstimates="fetchItems()" />

        <button class="btn btn-info btn-themex mb-2" v-on:click="handleShowNewEstimate()">New Estimate</button>

        <vue-good-table 
            :columns="columns" 
            :rows="estimates" 
            :lineNumbers="false"
            :search-options="{ enabled: true, placeholder: 'Search term' }" 
            :pagination-options="{ enabled: true,  position: 'bottom' }"
            @on-row-click="onRowClick"
            :selectOptions="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
            }"
        >
            <div slot="selected-row-actions" style="margin: -2px 0;">
                <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                <button class="btn btn-xs btn-primary">Action 2</button>
            </div>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                    <span class="label label-lg label-success">{{props.row.status}}</span>
                </span>
                <span v-else-if="props.column.field == 'products'">
                    <span v-for="(products, index) in props.row.products" :key="`product-${index}`" class="label label-lg label-theme m-r-3">{{ products }}</span>
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>

import NewEstimate from '@/components/slabsnz/NewEstimate.vue'

export default {
    name: 'Estimates',
    components: {
        NewEstimate
    },
    data() {
        return {
            estimates: [],
            columns: [
            {
                label: 'Estimate ID',
                field: 'id',
                width: '5%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            },
            {
                label: 'Name',
                field: 'name',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Address',
                field: 'address',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Town/City',
                field: 'city',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Job #',
                field: 'jobid',
                tdClass: 'text-nowrap',
                thClass: 'text-nowrap'
            }, {
                label: 'Quote #',
                field: 'quoteid',
                tdClass: 'text-nowrap',
                thClass: 'text-nowrap'
            }, {
                label: 'Products',
                field: 'products',
                tdClass: 'text-nowrap',
                thClass: 'text-nowrap'
            }, {
                label: 'Created On',
                field: 'createdAt',
                type: 'date',
                dateInputFormat: 'dd/MM/yyyy HH:mm:ss',
                dateOutputFormat: 'MMMM dd, yyyy HH:mm:ss',
                width: '10%',
                tdClass: 'text-center text-nowrap',
                thClass: 'text-center text-nowrap'
            }, {
                label: 'Updated On',
                field: 'updatedAt',
                type: 'date',
                dateInputFormat: 'dd/MM/yyyy HH:mm:ss',
                dateOutputFormat: 'MMMM dd, yyyy HH:mm:ss',
                width: '10%',
                tdClass: 'text-center text-nowrap',
                thClass: 'text-center text-nowrap'
            }],
        }
    },

    created ()
    {
        this.fetchItems();
    },

    methods: {
        fetchItems()
        {
            this.$http.get('/slabsnz/estimates').then((response) => {
                console.log(response.data)
                this.estimates = response.data
            })
        },
        onRowClick(params) {
            this.$router.push({ name: 'SNZEstimate', params: { id: params.row.id } })
        },
        handleShowNewEstimate() {
            this.$refs.newEstimate.showModal()
        }
    }
}
</script>