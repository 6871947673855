<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Workshop</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">Employee Time Reports</h1>
        <!-- end page-header --> 
        <!-- begin panel -->
        <b-row>
            <b-col md="6">
                <panel title="Generate Time Reports" noButton="true">
                    <b-form name="estimate" @submit.stop.prevent="generateReport()" ref="estimateForm">
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Report Type">
                                    <b-form-select v-model="form.reporttype" :options="reporttypes"></b-form-select>
                                </b-form-group>
                                <b-form-group label="From Date">
                                    <b-form-datepicker v-model="form.fromdate"></b-form-datepicker>
                                </b-form-group>
                                <b-form-group label="To Date">
                                    <b-form-datepicker v-model="form.todate"></b-form-datepicker>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group>
                                <template v-slot:label>
                                    Employees<br>
                                    <b-form-checkbox
                                        v-model="allSelected"
                                        :indeterminate="indeterminate"
                                        aria-describedby="employees"
                                        aria-controls="employees"
                                        @change="toggleAll"
                                    >
                                    {{ allSelected ? 'Un-select All' : 'Select All' }}
                                    </b-form-checkbox>
                                </template>

                                <b-form-checkbox-group
                                    id="employees"
                                    v-model="form.employees"
                                    :options="employees"
                                    name="employees"
                                    class="ml-4"
                                    aria-label="Individual employees"
                                    stacked
                                ></b-form-checkbox-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <b-button variant="themex" type="submit" :disabled="generatingReport">Generate Report</b-button>
                                 &nbsp; 
                                <b-button variant="themex" type="button" @click="viewLogs">View Logs</b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </panel>
            </b-col>
        </b-row>
        <b-row v-if="logs.length > 0">
            <div class="col-md-12">
                <table class="table table-striped table-bordered nowrap no-footer" cellspacing="0" id="timeview-log">
                    <thead>
                        <tr>
                            <th>Employee</th>
                            <th>Job</th>
                            <th>JobID</th>
                            <th>Description</th>
                            <th>Date In</th>
                            <th>Date Out</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="log in calcLogs" :key="log.id">
                            <td>{{log.employee}}</td>
                            <td>{{log.currentjob}}</td>
                            <td>{{log.currentjobid}}</td>
                            <td>{{log.currentdescription}}</td>
                            <td>{{log.datein}}</td>
                            <td>{{log.dateout}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-row>
    </div>
</template>

<script>

    export default {
        data(){
            return {
                employees: [],
                employeeObjs: [],
                reporttypes: [
                    { value: 1, text: 'Working Hours Report' }
                ],
                allSelected: false,
                indeterminate: false,
                generatingReport: false,
                logs: [],
                form: { 
                    reporttype: 1,
                    employees: [],
                    fromdate: this.$moment().startOf('week').add(1, 'm').toDate(),
                    todate: this.$moment().endOf('week').add(1, 'm').toDate(),
                }
            }
        },

        created() {
            this.loadEmployees()
        },

        watch: {
            'form.employees': function(newVal) {
                // Handle changes in individual employees checkboxes
                if (newVal.length === 0) {
                    this.indeterminate = false
                    this.allSelected = false
                } else if (newVal.length === this.employees.length) {
                    this.indeterminate = false
                    this.allSelected = true
                } else {
                    this.indeterminate = true
                    this.allSelected = false
                }
            } 
        },

        computed: {
            calcLogs() {
                let result = []

                for(let log of this.logs) {
                    let employee = this.employeeObjs.find(x => x.id === log.employee)
                    result.push({
                        'employee': (employee !== undefined ? employee.name : log.employee),
                        currentjob: log.currentjob,
                        currentjobid: log.currentjobid,
                        currentdescription: log.currentdescription,
                        datein: log.datein,
                        dateout: log.dateout
                    })
                }

                return result
            }
        },

        methods: {
            loadEmployees() {
                this.$http.get('/workshop/employees').then((response) => {
                    if(response) {
                        this.employeeObjs = response.data
                        for(var i = 0; i < response.data.length; i++) {
                            this.employees.push(response.data[i].name)
                        }
                    }
                })
            },

            toggleAll(checked) {
                this.form.employees = checked ? this.employees.slice() : []
            },

            generateReport() {
                this.generatingReport = true

                this.$http.post('/workshop/timereport', this.form, { responseType: 'arraybuffer' }).then(response => {
                    this.generatingReport = false
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Timereport.pdf'
                    link.click()
                    this.exportAsPdf = true
                }).catch((err) => {
                    this.generatingReport = true
                    console.log(err)
                })
            },

            viewLogs() {
                this.$http.post('/workshop/timereport/logs', this.form).then(response => {
                    this.logs = response.data
                }).catch((err) => {
                    console.log(err)
                })
            }
        }
    }
</script>