import Dashboard from '@/pages/Dashboard'
import Login from '@/pages/Login'
import Contacts from '@/pages/Contacts'
import Projects from '@/pages/Projects'
import ProjectOverview from '@/pages/Project/Overview'
import Stock from '@/pages/Stock'

/* Insulfound */
import InsulFoundQuotes from '@/pages/Insulfound/Quotes'
import InsulFoundQuoteOverview from '@/pages/Insulfound/Quote/Overview'

import InsulFoundOrders from '@/pages/Insulfound/Orders'
import InsulFoundOrderOverview from '@/pages/Insulfound/Order/Overview'

/* Slabs NZ */
import SNZEstimates from '@/pages/SlabsNZ/Estimates'
import SNZEstimateOverview from '@/pages/SlabsNZ/Estimate/Overview'

/* Workshop */
import TimeClock from '@/pages/Workshop/TimeClock'
import TimeReport from '@/pages/Workshop/TimeReport'

export default [
    {
        path: '*',
        redirect: '/dashboard',
        meta: {
            hideInMenu: true,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            hideInMenu: true,
            notCache: true,
            pageEmpty: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            title: 'Dashboard',
            roles: [1]
        }
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
        meta: {
            requiresAuth: true,
            title: 'Contacts',
            roles: [1]
        }
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
            requiresAuth: true,
            title: 'Projects',
            roles: [1]
        }
    },
    {
        path: '/projects/project/:id',
        name: 'Project',
        component: ProjectOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Project #${route.params.id}`,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/stock',
        name: 'Stock',
        component: Stock,
        meta: {
            requiresAuth: true,
            title: 'Stock',
            roles: [1]
        }
    },
    {
        path: '/stock/:id',
        name: 'Stock Item',
        component: Stock,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Item #${route.params.id}`,
            notCache: true,
            roles: [1]
        }
    },

    /* InsulFound */
    {
        path: '/insulfound',
        name: 'InsulFound',
        redirect: '/insulfound/quotes',
        meta: {
            hideInMenu: true,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/insulfound/quotes',
        name: 'InsulFoundQuotes',
        component: InsulFoundQuotes,
        meta: {
            title: 'Quotes',
            requiresAuth: true,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/insulfound/quotes/:id',
        name: 'InsulFoundQuoteOverview',
        component: InsulFoundQuoteOverview,
        props: true,
        meta: {
            title: route => `Quote #${route.params.id}`,
            requiresAuth: true,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/insulfound/orders',
        name: 'InsulFoundOrders',
        component: InsulFoundOrders,
        meta: {
            title: 'Orders',
            requiresAuth: true,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/insulfound/orders/:id',
        name: 'InsulFoundOrderOverview',
        component: InsulFoundOrderOverview,
        props: true,
        meta: {
            title: route => `Order #${route.params.id}`,
            requiresAuth: true,
            notCache: true,
            roles: [1]
        }
    },


    /* Slabs NZ */
    {
        path: '/slabsnz',
        name: 'SNZ',
        redirect: '/slabsnz/estimates',
        meta: {
            hideInMenu: true,
            notCache: true
        }
    },
    {
        path: '/slabsnz/estimates',
        name: 'SNZEstimates',
        component: SNZEstimates,
        meta: {
            title: 'Estimates',
            requiresAuth: true,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/slabsnz/estimates/:id',
        name: 'SNZEstimate',
        component: SNZEstimateOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Estimate #${route.params.id}`,
            notCache: true,
            roles: [1]
        }
    },


    /* Workshop Module */
    {
        path: '/workshop',
        name: 'Workshop',
        redirect: '/workshop/clock',
        meta: {
            hideInMenu: true,
            notCache: true,
            roles: [0, 1]
        }
    },
    {
        path: '/workshop/clock',
        name: 'TimeClock',
        component: TimeClock,
        meta: {
            title: 'Time Clock',
            requiresAuth: true,
            hideInMenu: true,
            notCache: true,
            pageEmpty: true,
            roles: [0, 1]
        }
    },
    {
        path: '/workshop/report',
        name: 'TimeReport',
        component: TimeReport,
        meta: {
            title: 'Time Report',
            requiresAuth: true,
            notCache: true,
            roles: [1]
        }
    },
]