<template>
    <b-modal id="newUnitModal" title="New Unit" button-size="sm" hide-footer>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form name="unit" @submit.stop.prevent="handleSubmit(onSubmit)" ref="unitForm">

                <validation-provider name="name" :rules="{ required: true, min: 5 }" v-slot="validationContext">
                    <b-form-group label="Details" label-for="name">
                        <b-form-input
                            id="name"
                            name="name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="name-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="name-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="note" :rules="{ required: false }" v-slot="validationContext">
                    <b-form-group label="Note" label-for="note">
                        <b-form-input
                            id="note"
                            name="note"
                            v-model="form.note"
                            :state="getValidationState(validationContext)"
                            aria-describedby="note-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="note-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="price" :rules="{ required: true, min: 1 }" v-slot="validationContext">
                    <b-form-group label="Unit Code" label-for="price">
                        <b-form-input
                            id="price"
                            name="price"
                            v-model="form.price"
                            :state="getValidationState(validationContext)"
                            aria-describedby="price-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="price-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="formula" :rules="{ required: false }" v-slot="validationContext">
                    <b-form-group label="Formula" label-for="formula">
                        <b-form-input
                            id="formula"
                            name="formula"
                            v-model="form.formula"
                            :state="getValidationState(validationContext)"
                            aria-describedby="formula-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="formula-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <b-button variant="default" @click="hideModal()">Cancel</b-button>
                &nbsp;
                <b-button variant="themex" type="submit">Add</b-button>

            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>

export default {
	name: 'NewUnit',
	data() {
        return {
            form: {
                category: 1,
                name: '',
                note: '',
                price: 0.00,
                quantity: 0,
                formula: ''
            }
        }
    },
    methods: {
        showModal(category) {
            this.form.category = category
            this.$bvModal.show('newUnitModal')
        },
        hideModal() {
            this.resetForm()
            this.$bvModal.hide('newUnitModal')
        },
        createEstimate(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.$refs.unitForm.submit()
        },

        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },

        resetForm() {
            this.form = {
                category: 1,
                name: '',
                note: '',
                price: 0.00,
                quantity: 0,
                formula: ''
            }

            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
        },

        onSubmit() {
            this.$emit('addNewUnit', this.form)
            this.hideModal()
        }
    }
}
</script>
