<template>
	<div class="fade page-sidebar-fixed page-header-fixed show page-container" v-if="!pageOptions.pageEmpty" v-bind:class="{ 
		'page-sidebar-minified': pageOptions.pageSidebarMinified, 
		'page-content-full-height': pageOptions.pageContentFullHeight, 
		'page-without-sidebar': pageOptions.pageWithoutSidebar, 
		'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
		'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
		'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
		'page-with-top-menu': pageOptions.pageWithTopMenu,
		'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
		'page-right-sidebar-toggled': pageOptions.pageMobileRightSidebarToggled || pageOptions.pageRightSidebarToggled,
		'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
		'has-scroll': pageOptions.pageBodyScrollTop
	}">
		<Header />
		<TopMenu v-if="pageOptions.pageWithTopMenu" />
		<Sidebar v-if="!pageOptions.pageWithoutSidebar" />
		<div id="content" class="content" v-bind:class="{ 'content-full-width': pageOptions.pageContentFullWidth, 'content-inverse-mode': pageOptions.pageContentInverseMode }">
			<div class="tag-nav-wrapper">
				<tag-navigation :value="$route" @input="handleClick" :list="tagNavList" @on-close="handleCloseTag" />
			</div>
			<keep-alive :include="cacheList">
				<router-view/>
			</keep-alive>
			<vue-ins-progress-bar></vue-ins-progress-bar>
		</div>
		<Footer v-if="pageOptions.pageWithFooter" />
	</div>
	<div v-else>
		<router-view></router-view>
		<vue-ins-progress-bar></vue-ins-progress-bar>
	</div>
</template>

<script>
import Sidebar from './components/sidebar/Sidebar.vue'
import Header from './components/header/Header.vue'
import TopMenu from './components/top-menu/TopMenu.vue'
import tagNavigation from './components/tag-navigation'
import Footer from './components/footer/Footer.vue'

import { mapMutations, mapActions } from 'vuex'
import { getNewTagList, routeEqual } from '@/libs/util'
import routes from '@/config/routes'
import config from '@/config'

export default {
	name: 'app',
	components: {
		Sidebar,
		Header,
		TopMenu,
		tagNavigation,
		Footer
	},

	computed: {
		tagNavList () {
			return this.$store.state.app.tagNavList
		},

		cacheList () {
			const list = ['ParentView', ...this.tagNavList.length ? this.tagNavList.filter(item => !(item.meta && item.meta.notCache)).map(item => item.name) : []]
			return list
		},
	},

	methods: {
		handleScroll: function() {
			this.pageOptions.pageBodyScrollTop = window.scrollY;
		},
		...mapMutations([
			'setBreadCrumb',
			'setTagNavList',
			'addTag',
			'closeTag',
			'setHomeRoute'
		]),
		...mapActions([
			'handleLogin'
		]),
		turnToPage (route) {
			let { name, params, query } = {}
			if (typeof route === 'string') 
				name = route
			else {
				name = route.name
				params = route.params
				query = route.query
			}

			if (name.indexOf('isTurnByHref_') > -1) {
				window.open(name.split('_')[1])
				return
			}
			this.$router.push({
				name,
				params,
				query
			})
		},
		handleCollapsedChange (state) {
			this.collapsed = state
		},
		handleCloseTag (res, type, route) {
			if (type !== 'others') {
				if (type === 'all') {
					this.turnToPage(config.homeName)
				} else {
					if (routeEqual(this.$route, route)) {
						this.closeTag(route)
					}
				}
			}
			this.setTagNavList(res)
		},
		handleClick (item) {
			this.turnToPage(item)
		}
	},
	watch: {
		'$route' (newRoute) {
			const { name, query, params, meta } = newRoute

			if(meta.pageEmpty !== undefined && meta.pageEmpty)
				this.pageOptions.pageEmpty = true;
			else
				this.pageOptions.pageEmpty = false;

			if(meta.hideInMenu)
				return

			this.addTag({
				route: { name, query, params, meta },
				type: 'push'
			})
			this.setBreadCrumb(newRoute)
			this.setTagNavList(getNewTagList(this.tagNavList, newRoute))
		}
	},

	mounted () {
		this.$insProgress.finish()
		this.setTagNavList()
		this.setHomeRoute(routes)
		const { name, params, query, meta } = this.$route

		if(!meta.hideInMenu) {
			this.addTag({
				route: { name, params, query, meta }
			})
			this.setBreadCrumb(this.$route)
		

			if (!this.tagNavList.find(item => item.name === this.$route.name)) {
				this.$router.push({
					name: config.homeName
				})
			}
		}
	},

	created() {
		//Expired Token
		this.$http.interceptors.response.use(undefined, (err) => {
			if (err.response.status === 401) {
				this.$store.dispatch('logout')
				this.pageOptions.pageEmpty = true;
				if(this.$route.name !== 'login')
					this.$router.push('/login')
					
				return Promise.reject(err)
			}
		});

		this.pageOptions.pageBodyScrollTop = window.scrollY;
		
		window.addEventListener('scroll', this.handleScroll);
		
		this.$insProgress.start()
		
		this.$router.beforeEach((to, from, next) => {
			this.$insProgress.start()
			next()
		})
		this.$router.afterEach(() => {
			this.$insProgress.finish()
		})
	}
}
</script>
