<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
			<li class="breadcrumb-item"><router-link :to="{ name: 'SNZ' }">Slabs NZ</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'SNZEstimates' }">Estimates</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Estimate #{{estimate.id}}</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header hidden-print">Estimate <small>{{estimate.id}}</small></h1>
        <!-- end page-header -->

        <div class="row">
            <div class="col-md-12">
                <span class="pull-right hidden-print">
                    <a href="javascript:;" class="btn btn-sm btn-white m-b-10" @click="handleDownloadQuotePdf()" :class="{ disabled: !exportAsPdf }"><i class="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i> Export as PDF</a>
                </span>
            </div>
        </div>

        <div class="row">
            <!-- begin col-xl-6 -->
            <div class="col-xl-2">
            <!-- begin panel -->
				<panel title="Estimate Details" noButton="true">
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form name="estimate" @submit.stop.prevent="handleSubmit(onSubmit)" ref="estimateForm">
                            <validation-provider name="name" rules="required|min:5" v-slot="validationContext">
                                <b-form-group label="Name" label-for="fullname">
                                    <b-form-input
                                        id="fullname"
                                        name="fullname"
                                        v-model="estimate.name"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="fullname-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="fullname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="email" rules="required|min:5|email" v-slot="validationContext">
                                <b-form-group label="Email" label-for="email">
                                    <b-form-input
                                        id="email"
                                        name="email"
                                        v-model="estimate.email"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="email-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="email-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="phonenumber" rules="required|min:5" v-slot="validationContext">
                                <b-form-group label="Phone Number" label-for="phonenumber">
                                    <b-form-input
                                        id="phonenumber"
                                        name="phonenumber"
                                        v-model="estimate.phonenumber"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="phonenumber-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="phonenumber-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="address" rules="required|min:5" v-slot="validationContext">
                                <b-form-group label="Address" label-for="address">
                                    <b-form-input
                                        id="address"
                                        name="address"
                                        v-model="estimate.address"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="address-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="address-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="town / city" rules="required|min:5" v-slot="validationContext">
                                <b-form-group label="Town / City" label-for="city">
                                    <b-form-input
                                        id="city"
                                        name="city"
                                        v-model="estimate.city"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="city-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="city-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="jobid" rules="required|min:1" v-slot="validationContext">
                                <b-form-group label="Job ID" label-for="jobid">
                                    <b-form-input
                                        id="jobid"
                                        type="number"
                                        name="jobid"
                                        v-model="estimate.jobid"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="jobid-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="jobid-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="quoteid" rules="required|min:1" v-slot="validationContext">
                                <b-form-group label="Quote ID" label-for="quoteid">
                                    <b-form-input
                                        id="quoteid"
                                        type="number"
                                        name="quoteid"
                                        v-model="estimate.quoteid"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="quoteid-live-feedback"
                                    ></b-form-input>
                                    <b-form-invalid-feedback id="quoteid-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider name="products" rules="required|min:1" v-slot="validationContext">
                                <b-form-group label="Products" label-for="products">
                                    <b-form-checkbox-group
                                        id="products"
                                        name="products"
                                        v-model="estimate.products"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="products-live-feedback"
                                    >
                                        <b-form-checkbox value="Slab">Slab</b-form-checkbox>
                                        <b-form-checkbox value="Excavation">Excavation</b-form-checkbox>
                                        <b-form-checkbox value="Drainage">Drainage</b-form-checkbox>
                                        <b-form-checkbox value="Insulfound">Insulfound</b-form-checkbox>
                                    </b-form-checkbox-group>
                                    <b-form-invalid-feedback id="products-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <template v-if="estimate && estimate.products && estimate.products.includes('Slab')">
                                <validation-provider name="slabtype" rules="required" v-slot="validationContext">
                                    <b-form-group label="Slab Type" label-for="slabtype">
                                        <b-form-select
                                            id="slabtype"
                                            name="slabtype"
                                            :options="slabtypes"
                                            v-model="estimate.slabtype"
                                            :state="getValidationState(validationContext)"
                                            aria-describedby="slabtype-live-feedback"
                                            disabled
                                        ></b-form-select>
                                        <b-form-invalid-feedback id="slabtype-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </template>

                            <b-form-group label="Created" label-for="created">
                                <b-form-input
                                    id="created"
                                    name="created"
                                    v-model="estimate.createdAt"
                                    disabled
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group label="Updated" label-for="updated">
                                <b-form-input
                                    id="updated"
                                    name="updated"
                                    v-model="estimate.updatedAt"
                                    disabled
                                ></b-form-input>
                            </b-form-group>
                            <!--
                            <div class="pull-right">
                                <b-button variant="default" @click="resetForm()">Reset</b-button>
                                &nbsp; 
                                <b-button variant="themex" type="submit" :disabled="!save">Save</b-button>
                            </div>
                            -->
                        </b-form>
                    </validation-observer>
				</panel>
				<!-- end panel -->
            </div>
            <!-- begin col-xl-3 -->
            <div class="col-xl-2">
            <!-- begin panel -->
				<panel title="Slab Measurements" noButton="true">
                    <b-form-group label="Slab Size" label-for="slabSize">
                        <b-input-group append="m2">
                        <b-form-input
                            id="slabSize"
                            name="slabSize"
                            v-model="estimate.measurements.slabSize"
                        ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Pod Size" label-for="podSize">
                        <b-input-group append="mm">
                        <b-form-input
                            id="podSize"
                            name="podSize"
                            v-model="estimate.measurements.podSize"
                        ></b-form-input>
                        </b-input-group>
                    </b-form-group>

                    <b-table-simple hover small responsive>
                        <b-tbody>
                            <b-tr>
                                <h5>Reinforcing</h5>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>

                    <b-form-group label="Corners" label-for="reinforcingCorners">
                        <b-form-input
                            id="reinforcingCorners"
                            name="reinforcingCorners"
                            v-model="estimate.measurements.reinforcingCorners"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Steel per Corner" label-for="reinforcingSteelPerCorner">
                        <b-form-input
                            id="reinforcingSteelPerCorner"
                            name="reinforcingSteelPerCorner"
                            v-model="estimate.measurements.reinforcingSteelPerCorner"
                        ></b-form-input>
                    </b-form-group>

                    <b-table-simple hover small responsive>
                        <b-tbody>
                            <b-tr>
                                <h5>Concrete</h5>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>

                    <b-form-group label="Concrete Depth" label-for="concreteDepth">
                        <b-input-group append="mm">
                            <b-form-input
                                id="concreteDepth"
                                name="concreteDepth"
                                v-model="estimate.measurements.concreteDepth"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Concrete Modifier" label-for="concreteModifier">
                        <b-form-input
                            id="concreteModifier"
                            name="concreteModifier"
                            v-model="estimate.measurements.concreteModifier"
                        ></b-form-input>
                    </b-form-group>

                    <b-table-simple hover small responsive>
                        <b-tbody>
                            <b-tr>
                                <b-td>Slab size is <span class="badge badge-warning badge-square badge-number">{{slabSize}}</span> <b>m2</b></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>Pod size is <span class="badge badge-warning badge-square badge-number">{{podSize}}</span> <b>mm</b></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td>
                                    Concrete volume at <span class="badge badge-warning badge-square badge-number">{{concreteDepth}}</span><b>mm</b> with modifier <span class="badge badge-warning badge-square badge-number">{{concreteModifier}}</span> is <span class="badge badge-warning badge-square badge-number">{{concreteVolume}}</span> <b>m3</b>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </panel>

                <panel title="Excavation" noButton="true">
                    <b-table-simple hover small responsive>
                        <b-tbody>
                            <b-tr>
                                <h5>Excavation</h5>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>

                    <b-form-group label="Excave past perimeter" label-for="excaveBeyondPerimeter">
                        <b-input-group append="m">
                            <b-form-input
                                id="excaveBeyondPerimeter"
                                name="excaveBeyondPerimeter"
                                v-model="estimate.measurements.excaveBeyondPerimeter"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group label="Excavation Depth" label-for="excaveDepth">
                        <b-form-input
                            id="excaveDepth"
                            name="excaveDepth"
                            v-model="estimate.measurements.excaveDepth"
                        ></b-form-input>
                    </b-form-group>

                    <b-table-simple hover small responsive>
                        <b-tbody>
                            <b-tr>
                                <h5>Backfill</h5>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-form-group label="Backfill Depth" label-for="backfillDepth">
                        <b-form-input
                            id="backfillDepth"
                            name="backfillDepth"
                            v-model="estimate.measurements.backfillDepth"
                        ></b-form-input>
                    </b-form-group>
                </panel>
            </div>
            <div class="col-xl-2">
                <panel title="Formulas" noButton="true">
                    You have access to:<br><br>

                    this.slabSize<br>
                    this.podSize<br>
                    this.podSpacers<br>
                    this.reinforcingHD12<br>
                    this.reinforcingCorners<br>
                    this.reinforcingSteelPerCorner<br>
                    this.concreteDepth<br>
                    this.concreteModifier<br>
                    this.concreteVolume<br>
                    this.perimeterLM<br>
                    this.excavePastPerimeter<br>
                    this.excaveDepth<br>
                    this.backfillDepth<br><br><br>
                    If something doesn't work, wrap it in Number()<br>
                    <br>
                    i.e. Number(this.slabSize) + .5
                </panel>
            </div>
			<!-- begin col-xl-6 -->
			<div class="col-xl-6">
				<!-- begin nav-tabs -->
				<b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="nav nav-tabs nav-tabs-inverse">
                    <template v-for="(category) in unitcategories">  
                        <!-- begin tab --> 
                        <b-tab v-if="estimate.products && estimate.products.includes(category.name)" :key="category.id">
                            <template slot="title">
                                <span class="d-sm-none">{{category.name}}</span>
                                <span class="d-sm-block d-none">{{category.name}}</span>
                            </template>
                            
                            <h4>{{category.title}}</h4>
                            <h4><small>{{category.description}}</small></h4>
                            <b-button class="pull-right" @click="addUnitModal(category.id)">Add Unit</b-button>
                            <div class="table-responsive">
                                <table class="table table-striped table-sm m-b-0 unit-table">
                                    <thead>
                                        <tr>
                                            <th>Details</th>
                                            <th>Notes</th>
                                            <th>Quantity</th>
                                            <th>Unit Cost</th>
                                            <th>Total Cost</th>
                                            <th>Formula</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="category.subcategory !== 0">
                                            <td :colspan="6" :style="{ 'background-color': '#92d050', 'font-size': '14px', 'font-weight': 'bold' }">{{category.name}}</td>
                                        </tr>
                                        <template v-for="(unit, index) in estimate.units">
                                            <tr v-if="unit && unit.category === category.category" :style="{ 'background-color': unit.color }" :key="unit.id">
                                                <td>{{unit.name}}</td>
                                                <td>{{unit.note}}</td>
                                                <td><input type="number" step="0.01" class="form-control quantity" v-model="unit.quantity" /></td>
                                                <td>${{unit.price}}</td>
                                                <td>${{calculateUnitTotal(unit)}}</td>
                                                <td><input type="text" class="form-control" v-model="unit.formula" v-on:blur="calculateQuantity(unit)" /></td>
                                                <td><button class="btn btn-sm btn-default" @click="removeUnit(index)"><i class="fas fa-times-circle"></i></button></td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td colspan="4"></td>
                                            <td><h5>${{calculateTotalForCategory(category.category)}}</h5></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                        <!-- end tab -->
                    </template>
				</b-tabs>
			</div>
        </div>
        <NewUnit ref="newUnit" @addNewUnit="addNewUnit" />
    </div>
</template>
<style scoped>
.unit-table input.form-control {
    height: 24px;
    font-weight:bold;
}
.unit-table input.form-control.quantity {
    width: 80px;
}

.badge-number {
    font-size:12px;
    font-weight:bold;
}
</style>
<script>
import NewUnit from '@/components/slabsnz/NewUnit.vue'

export default {
    props: ['id'],
    components: {
        NewUnit
    },
    data () {
        return {
            originalestimate: {},
            estimate: {
                measurements: {
                    excavation: {
                        excavation: {},
                        backfill: {},
                        sand: {},
                        geofabric: {},
                        geogrid: {}
                    }
                },
                units: {}
            },
            unitcategories: {},
            slabtypes: {},
            save: false,
            exportAsPdf: false
        }
    },

    created () {
        this.loadEstimate()
    },

    watch: {
        estimate: {
            handler() {
                this.saveEstimate()
            },
            deep: true
        },
        '$route' () { 
            this.loadEstimate()
        }
    },

    computed: {
        /* Slab Size */
        //slabSize() {
        //    let size = 0

        //    if(this.estimate.measurements.slab === undefined)
        //        return size

        //    for (let i = 0; i < this.estimate.measurements.slab.length; i++) {
        //        size += parseFloat(this.estimate.measurements.slab[i].width * this.estimate.measurements.slab[i].length)
        //    }

        //    return size.toFixed(3)
        //},

        //slabWidthCombined() {
        //    let size = 0

        //    if(this.estimate.measurements.slab === undefined)
        //        return size

        //    for (let i = 0; i < this.estimate.measurements.slab.length; i++) {
        //        size += parseFloat(this.estimate.measurements.slab[i].width)
        //    }

        //    return size
        //},

        //slabLengthCombined() {
        //    let size = 0

        //    if(this.estimate.measurements.slab === undefined)
        //        return size

        //    for (let i = 0; i < this.estimate.measurements.slab.length; i++) {
        //        size += parseFloat(this.estimate.measurements.slab[i].length)
        //    }

        //    return size
        //},

        /* Deductions */
        //deductionSize() {
        //    let deductions = 0;

        //    if(this.estimate.measurements.deductions === undefined)
        //        return deductions

        //    for (let i = 0; i < this.estimate.measurements.deductions.length; i++) {
        //        deductions += parseFloat(this.estimate.measurements.deductions[i].width * this.estimate.measurements.deductions[i].length)
        //    }

        //    return Number.parseFloat(deductions).toFixed(3)
        //},

        /* Perimeter Sizing */
        //perimeterSize() {
        //    let perimeter = 0;

        //    if(this.estimate.measurements.perimeter === undefined)
        //        return perimeter

        //    for (let i = 0; i < this.estimate.measurements.perimeter.length; i++) {
        //        perimeter += parseFloat(this.estimate.measurements.perimeter[i].width * this.estimate.measurements.perimeter[i].length)
        //    }

        //    return Number.parseFloat(perimeter).toFixed(2)
        //},

        /* Overall slab size */
        //overAllSlabSize() {
        //    return (this.slabSize - this.deductionSize).toFixed(3)
        //},

        /* Meters cubed slab */
        //slabDepthM3() {
        //    let depth = (this.slabSize - this.deductionSize) * this.estimate.measurements.depthfactor
        //    let margin = depth + (depth * (this.estimate.measurements.depthmargin / 100))
        //    return margin.toFixed(3)
        //},

        /* Excavation */
        slabSize() {
            return this.estimate.measurements.slabSize
        },

        podSize() {
            return this.estimate.measurements.podSize
        },

        podQuantity() {
            return this.slabSize * 0.7
        },

        podSpacers() {
            return this.podQuantity * 1.5
        },

        reinforcingHD12() {
            return this.slabSize * 0.6
        },

        reinforcingCorners() {
            return this.estimate.measurements.reinforcingCorners
        },

        reinforcingSteelPerCorner() {
            return this.estimate.measurements.reinforcingSteelPerCorner
        },

        concreteDepth() {
            return this.estimate.measurements.concreteDepth
        },

        concreteModifier() {
            return this.estimate.measurements.concreteModifier
        },

        concreteVolume() {
            return this.concreteModifier * this.slabSize
        },

        perimeterLM() {
            return this.slabSize * 0.5
        },

        excavePastPerimeter() {
            return this.estimate.measurements.excavePastPerimeter
        },

        excaveDepth() {
            return this.estimate.measurements.excaveDepth
        },

        backfillDepth() {
            return this.estimate.measurements.backfillDepth
        }
    },

    methods: {
        loadEstimate() {
            this.$http.get('/slabsnz/unitcategories').then((response) => {
                this.unitcategories = response.data
            })

            this.$http.get('/slabsnz/slabtypes').then((response) => {
                this.slabtypes = response.data
            })

            this.$http.get('/slabsnz/estimate/' + this.id).then((response) => {

                /* Hackfix Measurements */
                if(response.data[0].measurements === undefined || response.data[0].measurements === null)
                    response.data[0].measurements = {}

                //if(response.data[0].measurements.slab === undefined)
                //    response.data[0].measurements.slab = []

                //if(response.data[0].measurements.deductions === undefined)
                //    response.data[0].measurements.deductions = []

                if(response.data[0].measurements.excavation === undefined)
                    response.data[0].measurements.excavation = {
                        excavation: {},
                        backfill: {},
                        sand: {},
                        geofabric: {},
                        geogrid: {}
                    }

                this.estimate = response.data[0]
                this.originalestimate = this.estimate
            })
        },

        saveEstimate() {
            console.log('Saving')
            this.$http.put('/slabsnz/estimate/' + this.estimate.id, this.estimate).then(() => {
                this.recalculateAllFormulas()
            }) 
        },

        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null
        },

        resetForm() {
            this.estimate = this.originalestimate

            this.$nextTick(() => {
                this.$refs.observer.reset()
            });
        },

        onSubmit() {

        },

        //addSlabSize() {
        //    if(this.estimate.measurements.slab === undefined)
        //        this.$set(this.estimate.measurements, 'slab', [])

        //    this.$set(this.estimate.measurements.slab, this.estimate.measurements.slab.length, { width: 0, length: 0 })
        //},

        /* Remove slab size line item from measurements */
        //removeSlabSize(counter) {
        //    this.estimate.measurements.slab.splice(counter, 1)
        //},

        //addDeduction() {
        //    if(this.estimate.measurements.deductions === undefined)
        //        this.$set(this.estimate.measurements, 'deductions', [])

        //    this.$set(this.estimate.measurements.deductions, this.estimate.measurements.deductions.length, { width: 0, length: 0 })
        //},

        /* Remove deduction line item from measurements */
        //removeDeduction(counter) {
        //    this.estimate.measurements.deductions.splice(counter, 1)
        //},

        //addPerimeter() {
        //    if(this.estimate.measurements.perimeter === undefined)
        //        this.$set(this.estimate.measurements, 'perimeter', [])

        //    this.$set(this.estimate.measurements.perimeter, this.estimate.measurements.perimeter.length, { width: 0, length: 0 })
        //},

        /* Remove deduction line item from measurements */
        //removePerimeter(counter) {
        //    this.estimate.measurements.perimeter.splice(counter, 1)
        //},

        /* Calculate meters squared for arrays */
        //calculateM2(item) {
        //    return (item.width * item.length).toFixed(3)
        //},

        /* Calculate meters for arrays */
        //calculateM(item) {
        //    return (item.width * item.length).toFixed(2)
        //},

        /* Calculate unit total cost (quantity * unit price) */
        calculateUnitTotal(unit) {
            let total = (unit.quantity * unit.price).toFixed(2)
            if(isNaN(total)) {
                return Number(0.00).toFixed(2)
            }
            return total
        },

        /* Calculate total cost for category of units */
        calculateTotalForCategory(category) {
            let total = 0;

            for(let i = 0; i < this.estimate.units.length; i++) {
                if(!this.estimate.units[i])
                    continue

                let unittotal = parseFloat(this.estimate.units[i].quantity * this.estimate.units[i].price)
                if(this.estimate.units[i].category === category && !isNaN(unittotal)) {
                    total += unittotal
                }
            }

            return Number.parseFloat(total).toFixed(2)
        },

        calculateQuantity(unit) {
            let formula = unit.formula
            unit.quantity = Number(eval(formula)).toFixed(2)
        },

        recalculateAllFormulas() {
            for(let i = 0; i < this.estimate.units.length; i++) {
                if(this.estimate.units[i] === undefined || this.estimate.units[i] === null)
                    return

                let formula = this.estimate.units[i].formula
                this.estimate.units[i].quantity = Number(eval(formula)).toFixed(2)
            }
        },

        /* Add unit */
        addUnitModal(category) {
            this.$refs.newUnit.showModal(category)
        },

        addNewUnit(data) {
            console.log(data)
            this.$set(this.estimate.units, this.estimate.units.length, data)
        },

        /* Remove line item from units */
        removeUnit(index) {
            if(confirm('Are you sure you want to remove this unit?')) {
                this.estimate.units.splice(index, 1)
            }
        },
    }
}
</script>