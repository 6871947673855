<template>
	<div class="d-flex justify-content-center align-items-center min-vh-100">
		<div class="container">
            <div class="selected-employee">{{currentemployee.name}}</div>
            <b-row>
                <div class="col-sm-12 col-xs-12 employee-list">
                    <b-card no-body>
                        <b-tabs card vertical>
                            <b-tab v-for="(employee, index) in employees" v-bind:key="index" @click="selectEmployee(employee)">
                                <template v-slot:title>
                                    <b-badge pill :variant="clockedInBadge(employee)">{{'&nbsp;'}}</b-badge> <strong>{{employee.name}}</strong>
                                </template>
                                <b-card-text>
                                    <b-row>
                                        <div class="col-sm-10 col-xs-10">
                                            <dl>
                                                <dt>Last Action</dt>
                                                <dd>{{lastAction()}}</dd>
                                                <dt>Last Action Date</dt>
                                                <dd>{{lastActionDate()}}</dd>
                                                <dt>Current Job</dt>
                                                <dd>{{currentJob()}}</dd>
                                                <dt>Current Task</dt>
                                                <dd>{{currentTask()}}</dd>
                                                <dt>Current Tasks' Job</dt>
                                                <dd>{{currentTaskJob()}}</dd>
                                                <dt>Job ID</dt>
                                                <dd>{{currentJobID()}}</dd>
                                                <dt>Description</dt>
                                                <dd>{{currentDescription()}}</dd>
                                            </dl>
                                        </div>
                                        <div class="col-sm-2 col-xs-2">
                                            <div class="m-r-10">
                                                <template v-if="employee.status === 1">
                                                    <b-button block variant="themex" @click="handleShowJobSelector()">Switch Jobs</b-button>
                                                    <b-button block variant="themex" @click="lunchStart()" v-if="currentemployee.currentjob !== 'Lunch'">Lunch Start</b-button>
                                                    <b-button block variant="primary" @click="handleShowJobSelector()" v-if="currentemployee.currentjob === 'Lunch'">Lunch End</b-button>
                                                    <b-button block variant="themex" @click="clockOut()">Clock Out</b-button>
                                                </template>
                                                <template v-else>
                                                    <b-button block variant="themex" @click="handleShowJobSelector()">Clock In</b-button>
                                                </template>
                                            </div>
                                        </div>
                                    </b-row>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
            </b-row>
		</div>
        <JobSelector ref="jobSelector" @clockIn="clockIn" />
	</div>
</template>
<style scoped>
.selected-employee {
    font-size:25px;
    margin-bottom:5px;
}
.employee-list {
    font-size:13px;
}
.badge-active {
	background-color: #9cd84d !important;
}
</style>
<script>
import JobSelector from '@/components/job-selector/job-selector.vue'

export default {
    components: {
        JobSelector
    },
	created() {
        this.pageOptions.pageEmpty = true
        this.loadEmployees()
    },
	beforeRouteLeave (to, from, next) {
		this.pageOptions.pageEmpty = false
		next()
	},
	data() {
        return {
            employees: [],
            itr: 0,
            currentemployee: {},
            lastActionTypes: [
                'No action', //0
                'Clocked In', //1
                'Clocked Out', //2
                'Updated Job' //3
            ]
        }
    },
    watch: {
        employees: {
            handler() {
                this.saveEmployee()
            }
        },
        deep: true
    },
    methods: {
        loadEmployees() {
            this.$http.get('/workshop/employees').then((response) => {
                if(response) {
                    this.employees = response.data
                    this.currentemployee = this.employees[0]
                }
            })
        },

        saveEmployee() {
            this.$http.put('/workshop/employee/' + this.currentemployee.id, this.currentemployee).then(() => {
                
            })
        },

        selectEmployee(employee) {
            this.currentemployee = employee
        },

        clockedInBadge(employee) {
            return employee.status === 1 ? 'active' : 'inactive'
        },

        lastAction() {
            return this.lastActionTypes[this.currentemployee.lastaction]
        },

        lastActionDate() {
            return (this.currentemployee.lastactiondate !== '0000-00-00 00:00:00' ? this.$moment(this.currentemployee.lastactiondate).format('MMMM Do YYYY, HH:mm:ss a') : '--')
        },

        currentJob() {
            return (this.currentemployee.currentjob ? this.currentemployee.currentjob : '--')
        },

        currentTask() {
            return (this.currentemployee.currentjobtask ? this.currentemployee.currentjobtask : '--') + (this.currentemployee.currentjobtaskquantity > 0 && this.currentemployee.currentjob !== 'Stock' ? ' - Quantity: ' + this.currentemployee.currentjobtaskquantity : '')
        },

        currentTaskJob() {
            return (this.currentemployee.currenttaskjob ? this.currentemployee.currenttaskjob : '--')
        },

        currentJobID() {
            return (this.currentemployee.currentjobid ? this.currentemployee.currentjobid : '--')
        },

        currentDescription() {
            return (this.currentemployee.currentdescription ? this.currentemployee.currentdescription : '--')
        },

        clockIn(form) {
            this.currentemployee.status = 1 //Clocked In
            this.currentemployee.lastaction = 1 //Clocked In
            this.currentemployee.lastclockin = new Date()

            if(form.selectedJob) {
                this.currentemployee.currentjob = form.selectedJob
                this.currentemployee.currentjobid = form.jobId
                this.currentemployee.currentdescription = form.jobDescription
            }
            else {
                this.currentemployee.currentjob = ''
                this.currentemployee.currentjobid = ''
                this.currentemployee.currentdescription = ''
            }

            this.updateCurrentEmployee()
        },

        lunchStart() {
            this.currentemployee.status = 1 //Clocked In
            this.currentemployee.lastaction = 3 //Clocked In

            this.currentemployee.currentjob = 'Lunch'
            this.currentemployee.currentjobid = ''
            this.currentemployee.currentdescription = ''
            this.updateCurrentEmployee()
        },

        clockOut() {
            this.currentemployee.status = 2 //Clocked Out
            this.currentemployee.lastaction = 2 //Clocked out
            this.currentemployee.lastclockout = new Date()

            this.currentemployee.currentjob = ''
            this.currentemployee.currentjobid = ''
            this.currentemployee.currentdescription = ''

            this.updateCurrentEmployee()
        },

        indexForEmployee(id) {
            return new Promise((resolve, reject) => {
                this.employees.forEach((employee, index) => {
                    if(id === employee.id) {
                        resolve(index)
                    }
                })

                reject()
            })
        },

        updateCurrentEmployee() {
            this.indexForEmployee(this.currentemployee.id).then((index) => {
                if(index !== undefined) {
                    this.currentemployee.lastactiondate = new Date()
                    this.$set(this.employees, index, this.currentemployee)
                }
            })
        },

        handleShowJobSelector() {
            this.$refs.jobSelector.showModal()
        }
    }
}
</script>