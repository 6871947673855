<template>
	<div>
		<!-- begin breadcrumb -->
		<ol class="breadcrumb float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
			<li class="breadcrumb-item active"><a href="javascript:;">Contacts</a></li>
		</ol>
		<!-- end breadcrumb -->
		<!-- begin page-header -->
		<h1 class="page-header">Contacts <small>Leads, account holders, </small></h1>
		<!-- end page-header --> 


        <button class="btn btn-info btn-themex mb-2">New Contact</button>

        <vue-good-table 
            :columns="columns" 
            :rows="rows" 
            :lineNumbers="false"
            :search-options="{ enabled: true, placeholder: 'Search term' }" 
            :pagination-options="{ enabled: true,  position: 'bottom' }"
            @on-row-click="onRowClick"
            :selectOptions="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
            }"
        >
            <div slot="selected-row-actions" style="margin: -2px 0;">
                <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                <button class="btn btn-xs btn-primary">Action 2</button>
            </div>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                    <span class="label label-lg label-success">{{props.row.status}}</span>
                </span>
                <span v-else-if="props.column.field == 'interests'">
                    <span v-for="(interest, index) in props.row.interests" :key="`interest-${index}`" class="label label-lg label-theme m-r-3">{{ interest }}</span>
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
	</div>
</template>

<script>

    export default {
        data() {
            return {
                projects: [],
                columns: [
                {
                    label: 'Contact ID',
                    field: 'id',
                    width: '3%',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Name',
                    field: 'name',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Address',
                    field: 'address',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Email',
                    field: 'email',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Phone Number',
                    field: 'phonenumber',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Company',
                    field: 'company',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap'
                }, {
                    label: 'Interests',
                    field: 'interests',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap',
                    sortable: false,
                }, {
                    label: 'hasAccount',
                    field: 'hasaccount',
                    width: '5%',
                    thClass: 'text-nowrap',
                    tdClass: 'text-nowrap',
                    sortable: false,
                }, {
                    label: 'Status',
                    field: 'status',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap'
                }, {
                    label: 'Created On',
                    field: 'createdAt',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd',
                    dateOutputFormat: 'MMMM dd, yyyy',
                    width: '10%',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap'
                }],
                rows: [
                    //{ id:1, name:'', description: '', products: ['InsulFound', 'QuicFence'], status: 'Unknown', createdAt: '2018-10-31' }
                ]
            }
        },

        created ()
        {
            this.fetchItems();
        },

        methods: {
            fetchItems()
            {
                
            },
            onRowClick(params) {
                // params.row - row object 
                // params.pageIndex - index of this row on the current page.
                // params.selected - if selection is enabled this argument 
                // indicates selected or not
                // params.event - click event
                //this.$router.push({ name: '', params: { id: params.row.id } })
                console.log(params)
            }
        }
    }
</script>