<template>
	<div>
		<!-- begin breadcrumb -->
		<ol class="breadcrumb float-xl-right">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
			<li class="breadcrumb-item"><a href="javascript:;">Projects</a></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Project</a></li>
		</ol>
		<!-- end breadcrumb -->
		<!-- begin page-header -->
		<h1 class="page-header">Project <small>{{project.name}}</small></h1>
		<!-- end page-header --> 

	</div>
</template>

<script>
    export default {
        props: ['id'],
        data () {
            return {
                project: {}
            }
        },

        created () {
            this.loadProject();
        },

        methods: {
            loadProject()
            {
                this.$http.get('/project/' + this.id).then((response) => {
                    this.project = response.data
                })
            },
        }
    }
</script>